<template>

  <v-container
    fluid
    tag="section"
  >


      <!-- Diálogo de progresso -->
      <v-dialog v-model="showProgress" persistent max-width="300">
        <v-card>
          <v-card-title>
            Baixando arquivo...
          </v-card-title>
          <v-card-text>
            <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

  
  <v-dialog  v-model="dlg_infor"  width="500">
    <v-card>
      <v-card-title class="my-custom-card-title blue lighten-3">
        INFORMAÇÕES
        
      </v-card-title>

      <v-card-text>
        <b>Placa:</b> {{ this.value.placa }} <br/>
        <b>Descrição:</b> {{ this.value.descricao }} <br/>
        <b>Categoria:</b> {{ this.value.categoria }} 
        <b>Tipo:</b> {{ this.value.tipo }} <br/>
        <b>Fabricante:</b> {{ this.value.fabricante }} 
        <b>Ano de Fabricação:</b> {{ this.value.ano_fabricacao }} <br/>
        <b>Garantia:</b> {{ this.value.data_garantia }}  
        <b>Modelo:</b> {{ this.value.modelo }}  <br/>
        <b>Número de Série:</b> {{ this.value.numero_serie }}  <br/>
        <b>Peso:</b> {{ this.value.peso }} 
        <b>Largura:</b> {{ this.value.largura }} 
        <b>Altura:</b> {{ this.value.altura }} <br/>
        <b>Status:</b> {{ this.value.status }} 
        <b>Estado:</b> {{ this.value.estado }} <br/>

        <b>Proprietário:</b> {{ this.value.proprietario }} <br/>
        <b>Localização:</b> {{ this.value.nome_departamento }} <br/>
        <b>Observação:</b> {{ this.value.obs }} <br/>


     
        <b>Data Cadastro:</b> {{ this.value.data_insert }} 
        <b>Usuário Cadastro:</b> {{ this.value.user_name_insert }} <br/>
        
        <b>Data Atualização:</b> {{ this.value.data_update }} 
        <b>Usuário Atualização:</b> {{ this.value.user_name_update }} <br/>
      </v-card-text>
      
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          @click="dlg_infor = false">
          SAIR
        </v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>

<v-dialog  v-model="dlg_infor_mov"  width="500">
  <v-card>
    <v-card-title class="text-h4 blue lighten-3; padding: 0 !important">
      INFORMAÇÕES
      
    </v-card-title>

    <v-card-text>
      <b>Data Inicial:</b> {{ this.value.data }} <br/>
      <b>Data Final:</b> {{ this.value.dataf }} <br/>
      <b>Descrição:</b> {{ this.value.descricao }} 
      <b>Estado do Item:</b> {{ this.value.estado }} <br/>
      <b>Localização:</b> {{ this.value.nome_departamento }} 
      <b>Obs.:</b> {{ this.value.obs }} <br/>
      <b>Usuário do Cadastro:</b> {{ this.value.user_name_insert }}  <br/>
      <b>Data do Cadastro:</b> {{ this.value.data_insert }}  <br/>

    </v-card-text>
    
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="dlg_infor_mov = false">
        OK
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


<v-dialog  v-model="dlg_movimentacao"  width="800" >
  <v-card>
    <v-card-title class="my-custom-card-title blue lighten-3">
      MOVIMENTAÇÃO
    </v-card-title>


    <br/>

    <v-card-text>
      <v-form ref="form">
      <v-row>
      <v-col cols="6" sm="6">
        <v-select label="Ação *" small v-model="movimentacao.descricao"  :items="acoes"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled ></v-select>
      </v-col>

      <v-col cols="6" sm="6">
        <v-select label="Localização *" small v-model="movimentacao.departamento"  :items="lista_departamento_select"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled></v-select>
       </v-col>

     

      <v-col cols="6" sm="6">
        <v-text-field 
        label="Data Inicial *" small Required :rules="[v => !!v || 'Campo Obrigatório']" 
        filled v-model="movimentacao.data" 
        v-mask="'##/##/####'" 
      ></v-text-field>
      </v-col>

      <v-col cols="6" sm="6">
        <v-text-field 
        label="Data Final *" small Required :rules="[v => !!v || 'Campo Obrigatório']" 
        filled v-model="movimentacao.dataf" 
        v-mask="'##/##/####'" 
      ></v-text-field>
      </v-col>

      <v-col cols="6" sm="6">
        <v-select label="Estado do Produto" small v-model="movimentacao.estado"  :items="estado"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled></v-select>
       </v-col>

      <v-col cols="12" >
        <v-text-field 
          label="Obs." 
          filled v-model="movimentacao.obs" small
        ></v-text-field>
      </v-col>

      
      </v-row>
    </v-form>
      
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
       

        <v-btn
          color="success"
           outlined
          @click="dlg_movimentacao = false">
          SAIR
        </v-btn>

        <v-btn color="success" :loading="btn_load" @click="salva()">
          Confirmar
          <v-icon right dark>mdi-check</v-icon>
        </v-btn>


      </v-card-actions>

    </v-card-text>
    
   
  </v-card>
</v-dialog>

<v-dialog  v-model="dlg_upload"  width="800" >
  <br/>
  <v-form ref="form" enctype="multipart/form-data">

  <v-card>
        
    <v-card-title class="my-custom-card-title blue lighten-3">
      Upload de Documento
    </v-card-title>

<br/>
    <v-card-text>
     
      <v-row>
        <v-col cols="6" sm="6">
          <v-text-field  label="#" small filled readonly v-model="upload.idItem"> </v-text-field>
        </v-col>

        <v-col cols="6" sm="6">
          <v-text-field  label="Item" small filled readonly v-model="upload.aux"> </v-text-field>
        </v-col>

        <v-col cols="6" sm="6">
          <v-select label="Tipo de Documento *" small v-model="upload.tipo"  :items="docs"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled ></v-select>
        </v-col>

       

        <v-col cols="6" sm="6">
          <v-file-input  color="green accent-4"  :accept="'image/png, image/jpeg, image/jpg, application/pdf'"

            show-size filled
            label="Selecionar arquivo (PDF | PNG | JPEG | JPG) - Máx 10 mb" 
            @change="selectFile">
          
              <template v-slot:selection="{ text }">
                <v-chip
                  small
                  label
                  color="primary"
                >
                  {{ text }}
                </v-chip>
              </template>  
              
          </v-file-input>
        </v-col>

        <v-col cols="12" >
          <v-text-field 
            label="Obs." 
            filled v-model="upload.obs" small
          ></v-text-field>
        </v-col>

      </v-row>

   
    </v-card-text>

    
    <v-divider></v-divider>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined
          color="success"
           
          @click="dlg_upload = false">
          SAIR
        </v-btn>
        <v-btn color="success" :loading="this.btn_load"  @click="enviar()">
          Enviar
          <v-icon right dark>mdi-upload</v-icon>
        </v-btn>

      </v-card-actions>

   
  </v-card>
</v-form>

</v-dialog>


<v-dialog  v-model="dlg_movimentacao_lista"  width="800" >
  <v-card>
    <v-card-title class="my-custom-card-title blue lighten-3">
     HISTORICO DE MOVIMENTAÇÕES
    </v-card-title>

   
      <div style="overflow-y: auto; max-height: 400px;">
      <v-data-table  style="font-family:Arial;padding-left:20px;padding-top:8px"   :items-per-page="25"

        :items="movimentacoes"
        class="elevation-0">
    
        <template v-slot:item="{ item }">

         <b> # </b> {{ item.id }} <br/>
         <b> Descrição: </b> {{ item.descricao }} <br/>
         <b>Localização: </b> {{ item.nome_departamento }} 
         <b>Estado do Item: </b> {{ item.estado }} <br/>
         
         <b>Data Inicial: </b> {{ item.data }}
         <b>Data Final: </b> {{ item.dataf }} <br/>
         <b>Obs.: </b> {{ item.obs }}<br/>
         
         <b>Usuário: </b> {{ item.user_name_insert }}
         <b> Data de Criação: </b> {{ item.data_insert }}<br/>
         <br/>
         <divider></divider>

        </template> 


      </v-data-table>
      </div>
  
      <v-card-actions>
        <v-spacer></v-spacer>
       

        <v-btn
          color="success"
           outlined
          @click="dlg_movimentacao_lista = false">
          SAIR
        </v-btn>

    
      </v-card-actions>    
   
  </v-card>
</v-dialog>

<v-dialog  v-model="dlg_download_lista"  width="600" >
  <v-card>
    <v-card-title class="my-custom-card-title blue lighten-3">
    DOCUMENTOS
    </v-card-title>

   
      <div style="overflow-y: auto; max-height: 400px;">
      <v-data-table  style="font-family:Arial;padding-left:20px;padding-top:8px"   :items-per-page="25"

        :items="documentos"
        class="elevation-0">
    
        <template v-slot:item="{ item }">

         <b> # </b> {{ item.idItem }} <br/>
         <b>Tipo: </b> {{ item.tipo }} <br/>
         <b>Name: </b> {{ item.file_name }} <br/>
         <b>Obs.: </b> {{ item.obs }}<br/>
         
         <b>Usuário: </b> {{ item.user_name_insert }}<br/>
         <b> Data de Criação: </b> {{ item.data_insert }}<br/>
       
         <br/>
         <v-btn   dark   small
         color="success" :loading="btn_load_baixa"
          @click="baixa(item.id, item.file_name)">
         DOWNLOAD
                   <v-icon right dark>mdi-download</v-icon>

         </v-btn> 
         <br/> <br/>
       <divider></divider>


        </template> 


      </v-data-table>
      </div>
  
      <v-card-actions>
        <v-spacer></v-spacer>
       

        <v-btn
          color="success"
           outlined
          @click="dlg_download_lista = false">
          SAIR
        </v-btn>

    
      </v-card-actions>    
   
  </v-card>
</v-dialog>

<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text> 
            {{msg_dialog}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>


<v-container >
  <v-col cols="12">
    <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'mes'"
    @filtrar="filterData" :title_opcao="'Status'" 
      ></filtro-component>
  </v-col>
</v-container>


<base-material-card
      icon="mdi-folder-open"
      title="Inventário"
      class="px-5 py-3">


      <v-spacer></v-spacer>
      <v-row class="text-right">
        <v-col cols="6" />

        <v-col cols="6" class="text-right">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisa número da placa"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>



      <br/>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="50" 
        class="elevation-0">

        <template v-slot:top>
          <v-progress-linear
            v-if="isLoading" 
            indeterminate
            color="primary"
          ></v-progress-linear>
        </template>


                  <template v-slot:item="row">
                    <tr>
                      <td style="width: 6%;font-size:16px;">{{row.item.id}} </td>
                      <td style="width: 10%;font-size:16px;">{{row.item.placa}} </td>
                      <td style="width: 20%;font-size:16px;">{{row.item.tipo}} </td>
                      <td style="width: 25%;font-size:16px;">{{row.item.nome_departamento}} </td>

                      <td style="width:5%;font-size:14px;">
                        <v-chip 
                        :color="getStatus(row.item.status)"
                        text-color="white">
                            {{row.item.status}}                    
                            
                        </v-chip>  
                      </td>



                      <td style="width: 30%;font-size:14px;">
                       

                        <v-tooltip bottom >    
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-2"   fab  dark   small v-bind="attrs" v-on="on" @click="setInfor(row.item)" 
                            color="blue">
                              <v-icon dark>
                                mdi-information
                              </v-icon>
                            </v-btn> 
                          </template>
                          <span>Detalhe</span>
                        </v-tooltip>   
                      

                       <v-tooltip bottom >    
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mx-2"   fab  dark   small v-bind="attrs" v-on="on" @click="setMovimentacao(row.item)" 
                          color="orange">
                            <v-icon dark>
                              mdi-wrench
                            </v-icon>
                          </v-btn> 
                        </template>
                        <span>Movimentação</span>
                      </v-tooltip>  


                      <v-tooltip bottom >    
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mx-2"   fab  dark   small v-bind="attrs" v-on="on" @click="setMovimentacaoLista(row.item)" 
                          color="green">
                            <v-icon dark>
                              mdi-list-box
                            </v-icon>
                          </v-btn> 
                        </template>
                        <span>Lista</span>
                      </v-tooltip>  

                      <v-tooltip bottom >    
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mx-2"   fab  dark   small v-bind="attrs" v-on="on" @click="setUpload(row.item)" 
                          color="purple">
                            <v-icon dark>
                              mdi-image
                            </v-icon>
                          </v-btn> 
                        </template>
                        <span>Lista</span>
                      </v-tooltip>  


                      <v-tooltip bottom >    
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mx-2"   fab  dark   small v-bind="attrs" v-on="on" @click="setDonwload(row.item)" 
                          color="orange">
                            <v-icon dark>
                              mdi-eye
                            </v-icon>
                          </v-btn> 
                        </template>
                        <span>VER DOCUMENTOS</span>
                      </v-tooltip>  

                      </td>
                      </tr>
                      </template>

      </v-data-table>


  
      <div class="d-flex justify-end" style="color:green; font-size: 28px; margin-right: 12px;">
        <span @click="download_xls" style="cursor: pointer;"><i class="fas fa-file-excel"></i></span>
      </div>

</base-material-card>
  </v-container>    
</template>
<script>

import Documento from '../../services/documento'
import Item from '../../services/item'
import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto

  export default {
    name: 'item',
   
    components: {
      FiltroComponent,
    },

    mounted () {
      this.lista();
      this.lista_departamento();
    
    },

   


     created() {
      this.selectedStartDate = this.getFirstDayOfYear(),
      this.selectedEndDate =  this.formatDateBR(new Date())
    },

    data () {
      return {
        components: {
            DashboardCoreView: () => import('../dashboard/components/core/View'),
        },
        headers: [
          { text: '#', value: 'codigo' },
          { text: 'Placa', value: 'placa' },
          { text: 'Produto', value: 'desricao' },
          { text: 'Localização', value: 'id_departamento' },
          { text: 'Status', value: 'status' },
          { text: '', value: '' },

        ],
        expanded: [],
        singleExpand: false,

        headers_movimentacao: [
          { text: 'Ação', value: 'descricao' },
          { text: 'Localização', value: 'localizacao' },
          { text: 'Nome', value: 'nome' },
          { text: 'Data', value: 'data' },
        ],
        acoes: ['MANUTENÇÃO PREVENTIVA', 'MANUTENÇÃO CORRETIVA', 'MOVIMENTAÇÃO DE EMPRESA', 'INATIVAÇÃO', 'ATIVAÇÃO'],
        estado: ['NOVO', 'SEMI-NOVO', 'COM DEFEITO'],

        alert_msg:false,
        search: '',
        currentFile: undefined,
        progress: 0,
        showProgress: false,

        groupByOptions: ["proprietario", "categoria", "Não Agrupar"],
        groupByOption: 'Não Agrupar',
        msg_dialog:'',
        docs: ['Foto', 'Comprovante Pagamento', 'Nota Fiscal', 'Termo de Serviço', 'Outros'],
        btn_load: false,
        btn_load_donwload: false,
        dlg_infor: false,
        dlg_movimentacao: false,
        dlg_movimentacao_lista: false,
        dlg_download_lista: false,
        dlg_upload: false,
        dlg_infor_mov: false,
        btn_load_baixa: false,
          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todas",
          selectedOpcao: "Todos",
          selectMode: "Cadastro",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: ["Todos","Ativo","Inativo"],
          modos: ['Cadastro', 'Garantia'],
          categoria: [],
          showFilter: false,
          empresas: ["SEDE CORPORATIVA", "CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PARQUE DAS FLORES", "PREVIDA", "MELRO E SAMPARIO", "RESERVA DA PAZ",  "GPF - CORRETORA",  "TODAS"],

          tipos: ['Todas',  'Suprimentos de Escritório',
            'Mobiliário de Escritório',
            'Tecnologia',
            'Papelaria',
            'Equipamento de Apresentação',
            'Materiais de Limpeza',
            'Armazenamento',
            'Eletrônicos',
            'Segurança',
            'Cuidados com os Funcionários',
            'Móveis',
            'Eletroeletrônicos',
            'Outros'],

        items: [],
        value: {},
        upload:{
          tipo: '',
          obs: '',
          idItem: '',
          file_name: '',
          aux: '',
        },

        loading_download:false,
        n_download: '',
       
        movimentacao: {
          descricao: '',
          data: '',
          departamento: '',
          idDepartamento: '',
          nome_departamento: '',
          estado: '',
          obs: '',
          idItem: ''
        },
        movimentacoes: [],
        documentos: [],
        departamentos: [],
        lista_departamento_select: [],
        departamentoMapping: {}, // Objeto para mapear texto para id

        item:{
            descricao:'',
            tipo: '',
            fabricante: '',
            categoria: '',
            ano_fabricacao: '',
            peso: '',
            largura: '',
            altura: '',
            placa: '',
            departamento: '',
            obs: '',
            proprietario: '',
            empresa: '',
            id_departamento: ''
        },
      }
    },

   
    methods: {

        getFirstDayOfYear() {
          const dataAtual = new Date();
          const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), 0, 1); // Janeiro é representado como 0
          return this.formatDateBR(primeiroDiaDoAno);
        },

          formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
        },

        filterData(valores) {

            this.isLoading = true;
            this.items =  [],
            this.selectedType = valores.selectedType;
            this.selectedOpcao = valores.selectedOpcao;
            this.selectedCompany = valores.selectedCompany;
            this.selectMode = valores.selectMode;
            this.selectedStartDate = valores.selectedStartDate;
            this.selectedEndDate = valores.selectedEndDate;
            this.lista();
            },

        selectFile(file) {
          this.progress = 0;
          this.currentFile = file;
          this.upload.file_name = file.name;
        },

        clear () {
          this.$refs.form.reset();
        },
  

        setInfor(valor){
            this.value = valor;
            this.dlg_infor = true;
        },
      
        setUpload(valor){
            this.upload = {};
            this.value = valor;
            this.upload.idItem = valor.id;
            this.upload.aux = valor.tipo;
            this.upload.tipo = '';
            this.dlg_upload = true;
        },

        setDonwload(valor){
          
            this.value.id = valor.id;
            this.lista_documentos(this.value);
            this.$refs.form.reset();
          

        },

        setMovimentacao(valor){
            this.value = valor;
            this.dlg_movimentacao = true;
            this.movimentacao.idItem = this.value.id;
            this.movimentacao.departamento = this.value.nome_departamento;
        },

        setMovimentacaoLista(valor){
            this.value = valor;
            this.movimentacao.idItem = this.value.id;
            this.lista_movimentacao();
            this.dlg_movimentacao_lista = true;
        },

        getStatus (status) {
            switch (status) {
            case 'Inativo': return 'red darken-2'
            case 'Inativa' : return 'red lighten-1'
            case 'Ativo' : return 'blue lighten-1'
            case 'ativa' : return 'blue lighten-1'
              default: 'success'
            }
        },

        download_xls() {
             
             this.showProgress = true;      
             const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
             const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              Item.lista_xls(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao)
             .then(response => {
                       if (response.status === 200) {
                        
                               const fileName = `inventario_${Date.now()}.xlsx`;
                               const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                               const fileLink = document.createElement('a')
                               fileLink.href = fileURL
                               fileLink.setAttribute('download', fileName)
                               document.body.appendChild(fileLink)
                               fileLink.click()
                            
                       }
                     })
                     .catch(error => {
                       // Lida com erros
                       console.error('Erro ao baixar o arquivo Excel:', error);
                     }) .finally(() => {
                       // Após o término do download, oculte o diálogo de progresso
                       this.showProgress = false;
                     });
           },



        lista(){
          this.isLoading = true;

          const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
          const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

         
          Item.lista_filtro(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao).then(response => {
                  if(response.status = 200){
                    this.items = response.data;
                  }
                }).catch(e => {
                  this.isLoading = false;

                }).finally(() => {
                  this.isLoading = false;

              });

        },

        lista_movimentacao(){
          Item.lista_movimentacao(this.value.id).then(response => {
                  if(response.status = 200){
                    this.movimentacoes = response.data;
                    
                  }
                }).catch(e => {
                }).finally(() => {
                  
              });

        },

        lista_documentos(valor){
        this.btn_load_donwload = true;
        Item.lista_documentos(valor.id).then(response => {
                if(response.status = 200){
                  this.documentos = response.data;
                  
                }
              }).catch(e => {
                this.btn_load_donwload = false;

              }).finally(() => {
                this.btn_load_donwload = false;
                this.dlg_upload = false;
                this.dlg_download_lista = true;
            });

        },

        lista_departamento(){
        Documento.lista().then(response => {
                if(response.status = 200){this.departamentos = response.data;
                  this.departamentos = response.data;
                  this.lista_departamento_select = this.departamentos.map((departamento) => {
                  const texto = `${departamento.descricao} - ${departamento.empresa}`;
                  this.departamentoMapping[texto] = departamento.id;
                  return texto;
                  });
                }
              }).catch(e => {
              }).finally(() => {
                
            });

        },

        salva(){
          
        const id = this.departamentoMapping[this.movimentacao.departamento];
        this.movimentacao.idDepartamento = id;
        this.movimentacao.nome_departamento = this.movimentacao.departamento;

        this.btn_load = true;

          if(this.$refs.form.validate()){

                Item.salva_movimentcao(this.movimentacao).then(response => {

                      if (response.status === 200) {
                          this.msg_dialog = "Cadastrado com Sucesso";
                      } 

                    }).catch(e => {
                        this.loading = false
                        this.btn_load = false;
                       
                        if (e.response.status !== 403) {
                              this.msg_dialog = "Por Favor, Tente novamente ou verifique sua conexão";
                          } else{
                              this.msg_dialog = "Você não tem permissão para realizar esta ação.";
                          } 


                  }).finally(() => {
                      this.loading = false
                      this.btn_load = false;
                      this.lista();
                      this.lista_movimentacao();
                      this.clear();
                      this.alert_msg = true;
                      this.dlg_movimentacao = false;

                    })
                
          }else{
              this.alert_msg = true;
              this.btn_load = false;
              this.msg_dialog = "Preencha todos os campos";

          }
        },

      

        enviar(){
            if (!this.currentFile) {
              alert('Por favor, selecione um arquivo'+ this.upload.file_name);
              return;
            }
            this.btn_load = true;

            if(this.$refs.form.validate()){
              if(this.currentFile.size< 9999999){
              
                  let formData = new FormData();
                  formData.append("file", this.currentFile);
                  formData.set("obs", this.upload.obs);
                  formData.set("tipo", this.upload.tipo);
                  formData.set("file_name", this.upload.file_name.normalize());
                  formData.set("idItem", this.upload.idItem);
                  Item.upload(formData).then(response => {

                    if (response.status === 200) {
                          this.msg_dialog = "Enviado com Sucesso";
                          this.clear();
                      } 

                      }).catch(e => {

                        if (e.response.status == 403) {
                          this.msg_dialog = "Você não tem permissão para realizar esta ação.";
                      } 
                      
                      if(e == 'Error: timeout of 8000ms exceeded'){
                          this.btn_load = false;
                          this.msg_dialog = "Por Favor, Tente novamente ou verifique sua conexão";
                          this.dlg_upload = false;
                      }

                    }).finally(() => {
                        this.loading = false
                        this.btn_load = false;
                        this.dlg_upload = false;
                        this.alert_msg = true;

                      })
              
              }else{
                this.alert_msg = true;
                this.btn_load = false;
                this.msg_dialog = "Tamanho máximo 10 MB";
              }       
            }else{
                this.alert_msg = true;
                this.btn_load = false;
                this.msg_dialog = "Preencha todos os campos";
            }
        },
        
        
        baixa(id, file_name) {
          this.showProgress = true;   
            this.btn_load = true;
            this.btn_load_baixa = true;
            Item.download(id).then(response => {
              if (response.status === 200) {
                if (response.data.size > 1) {
                  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                  const fileLink = document.createElement('a');
                  fileLink.href = fileURL;
                  fileLink.setAttribute('download', file_name);
                  document.body.appendChild(fileLink);
                  fileLink.click();
                  this.msg_dialog = 'Download Concluido';

                }
              } else {
                this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte';
              }
             

            }).catch(e => {
                this.btn_load_baixa = false;

                if (e.response.status !== 403) {
                              this.msg_dialog = "Por Favor, Tente novamente ou verifique sua conexão";
                          } else{
                              this.msg_dialog = "Você não tem permissão para realizar esta ação.";
                  } 

              
            }).finally(() => {
              this.showProgress = false;   
              this.loading = false;
              this.btn_load = false;
              this.dlg_upload = false;
              this.btn_load_baixa = false;
              this.alert_msg = true;
              this.loading_download = false;
              this.n_download = '';


            });
          }

        }
    
    

  }


   
  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }

   
  
</script>

<style scoped>
.my-custom-card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px; /* Ajuste o tamanho da fonte conforme necessário */
  font-weight: 300 !important;
  padding: 10px 0px 10px !important;
}
</style>
